import { axiosClient } from "./quoteApi";

class InvoiceApi {
    createInvoice = (idToken: any, body: any) => {
        const url = `invoices`
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.post(url, body, config);
    };

    getInvoiceDetail = (idToken: any, id: any) => {
        const url = `invoices/${id}`
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.get(url, config);
    };

    getLineInvoiceDetail = (idToken: any, id) => {
        let url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}`;
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` },
        };
        return axiosClient.get(url, config);
    };

    updateInvoice = (idToken: any, body: any, id: any) => {
        const url = `invoices/${id}`
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.put(url, body, config);
    };
    createPaymentIntent = (idToken: any, id: any) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}/create_payment_intent`
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.post(url, undefined, config);
    }

    updatePaymentIntent = (idToken: any, id: any, payment_id) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}/payment_success`
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.put(url, { payment_id }, config);
    }

    updatePaymentIntentCash = (idToken: any, id: any, body) => {
        const url = `${process.env.REACT_APP_SERVER_API_LINE}/line/api/invoices/${id}/payment_success`
        const config: any = {
            headers: { Authorization: `Bearer ${idToken}` }
        }
        return axiosClient.put(url, body, config);
    }
}
const invoiceApi = new InvoiceApi()
export default invoiceApi